<div class="interacts-present" *ngIf="showInteractsPresent">
  <ng-lottie
    [options]="{path: '/assets/animations/touch.json'}"
  ></ng-lottie>
</div>
<div class="interacts-present-bottom"
     *ngIf="!hideTabBar"
>
  <span class="jump">Touch the screen to interact</span>
  <div class="interacts-present-bottom-line"></div>
</div>

<div
  *ngIf="withInvoice"
  class="tab-bar tab-bar__with-bg"
  [class.hide]="!hideTabBar"
>
  <div class="tabs" #tabs appActivitySection="TabBar">
    <div class="tabs__side left">
      <button
        class="tab"
        [class.underline]="isMenuPage"
        [routerLink]="isMenuPage ? '/main/home' : '/main/canadian-brewhouse/menu'"
        routerLinkActive="active"
        [appUserActivityClickTracking]="isMenuPage ? 'MenuClose' : 'MenuOpen'"
      >
        <div class="icon">
          <img
            [src]="isMenuPage
              ? '/themes/canadian-brewhouse/assets/images/menu_selected.svg'
              : '/themes/canadian-brewhouse/assets/images/menu_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isMenuPage">
          <span>{{ 'tabBar.menu' | translate }}</span>
        </div>
      </button>

      <button
        class="tab"
        [class.underline]="isMorePage"
        [routerLink]="isMorePage ? '/main/home' : '/main/canadian-brewhouse/more'"
        routerLinkActive="active"
        [appUserActivityClickTracking]="isMorePage ? 'MoreClose' : 'MoreOpen'"
      >
        <div class="icon">
          <img
            [src]="isMorePage
              ? '/themes/canadian-brewhouse/assets/images/new_selected.svg'
              : '/themes/canadian-brewhouse/assets/images/new_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isMorePage">
          <span>{{ 'tabBar.more' | translate }}</span>
        </div>
      </button>
    </div>
    <!--      надпис тут-->
    <button
      [class.show]="withCallWaiter && isHomePage"
      class="tab logo"
      routerLink="/main/home/service"
      routerLinkActive="active"
      appUserActivityClickTracking="ServicePageOpen"
    >
      <img class="logoImg" src="/themes/canadian-brewhouse/assets/images/logo.svg" alt=""/>
    </button>

    <button
      [class.show]="withCallWaiter && !isHomePage"
      class="tab logo"
      routerLink="/main/home"
      routerLinkActive="active"
      appUserActivityClickTracking="PageClose"
    >
      <img class="logoImg" src="/themes/canadian-brewhouse/assets/images/logo_clear.svg" alt=""/>
    </button>

    <button
      [class.show]="!withCallWaiter"
      class="tab logo"
      routerLink="/main/home"
      routerLinkActive="active"
      appUserActivityClickTracking="PageClose"
    >
      <img class="logoImg" src="/themes/canadian-brewhouse/assets/images/logo.svg" alt=""/>
    </button>

    <div class="tabs__side right">
      <button
        class="tab"
        [class.underline]="isStorePage"
        [routerLink]="isStorePage ? '/main/home' : '/main/canadian-brewhouse/store'"
        routerLinkActive="active"
        [appUserActivityClickTracking]="isStorePage ? 'StorePageClose' : 'StorePageOpen'"
      >
        <div class="icon">
          <img
            [src]="isStorePage
              ? '/themes/canadian-brewhouse/assets/images/store_selected.svg'
              : '/themes/canadian-brewhouse/assets/images/store_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isStorePage">
          <span>{{ 'tabBar.store' | translate }}</span>
        </div>
      </button>

      <button
        class="tab"
        [class.underline]="isGetRewardedPage"
        [routerLink]="isGetRewardedPage ? '/main/home' : '/main/canadian-brewhouse/rewarded'"
        routerLinkActive="active"
        [appUserActivityClickTracking]="isGetRewardedPage ? 'GetRewardedPageClose' : 'GetRewardedPageOpen'"
      >
        <div class="icon">
          <img
            [src]="isGetRewardedPage
              ? '/themes/canadian-brewhouse/assets/images/rewards_selected.svg'
              : '/themes/canadian-brewhouse/assets/images/rewards_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isGetRewardedPage">
          <span>{{ 'tabBar.rewards' | translate }}</span>
        </div>
      </button>
    </div>
  </div>
</div>
