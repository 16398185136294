<div
  *ngIf="timer.active || timer.now"
  class="happy-hours"
  routerLink="/main/menu"
  appActivitySection="HappyHours"
  appUserActivityClickTracking="OpenHappyHours"
>
  <div class="first">
    <div [innerHTML]="'happyHour.label' | translate"></div>
  </div>
  <div class="second">
    <div class="timer">
      <div>{{ timer.activeCountdownTime }}</div>
    </div>
    <div class="label">
      <div class="menu">
        {{ 'happyHour.viewMenu' | translate }}
      </div>
      <div class="timer-label" *ngIf="timer.now">
        {{ 'happyHour.timeLeft' | translate }}
      </div>
      <div class="timer-label" *ngIf="!timer.now">
        {{ 'happyHour.countdown' | translate }}
      </div>
    </div>
  </div>
</div>
