import { environment as original } from './environment.dev';

export const environment = {
  ...original,
  name: 'dev-canadian-brewhouse-style',
  backgroundAdPlayerColor: '#000000',
  featureToggle: {
    ...original.featureToggle,
    theme: 'canadian-brewhouse',
    withServiceCentre: true,
    withCallWaiterOnOffline: true,
    tabBarAutoHide: true,
    idleDelay: 30,
    ambientLight: {
      callWaiter: { r: 255, g: 0, b: 0, a: 1 },
      callWaiterToPay: { r: 255, g: 255, b: 0, a: 1 },
      callWaiterToRepeat: { r: 0, g: 255, b: 0, a: 1 },
    },
  }
};
