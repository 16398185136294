<app-loading *ngIf="isLoading; else content"></app-loading>

<ng-template #content>
  <div
    *ngIf="isTabsShow"
    class="tabs"
    [class.fixed]="tabs.isSticky"
    appActivitySection="MenuCategories"
  >
    <button
      *ngIf="isBackTabShow; else tabsButtons"
      class="tab tab__full"
      [class.tab__main] = "tabs.activeId == 'tabMain'"
      [class.tab__happy_hours] = "tabs.activeId == 'tabHappyHours'"
      (click)="setActiveTab(tabs.activeId)"
      appUserActivityClickTracking="BackToCategory"
    >
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" stroke-width="0" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.14282 12C7.62854 14 7.28568 11.8286 6.94282 11.4857L2.65711 7.2C1.97139 6.51429 1.97139 5.48571 2.65711 4.8L6.94282 0.514286C7.62854 -0.171429 8.65711 -0.171429 9.34282 0.514286C10.0285 1.2 10.0285 2.22857 9.34282 2.91428L6.25711 6L9.34282 9.08571C10.0285 9.77143 10.0285 10.8 9.34282 11.4857C8.99996 11.8286 8.65711 14 8.14282 12Z" fill="white" />
      </svg>
      {{ 'menu.tabs.backToCategory' | translate }}
    </button>

    <ng-template #tabsButtons>
      <button class="tab tab__main" [class.tab__active]="tabs.activeId == 'tabMain'" (click)="setActiveTab('tabMain')" appUserActivityClickTracking="ShowMainMenus">
        {{ 'menu.tabs.main' | translate }}
      </button>
      <button class="tab tab__happy_hours" [class.tab__active]="tabs.activeId == 'tabHappyHours'" (click)="setActiveTab('tabHappyHours')" appUserActivityClickTracking="ShowHappyHoursMenus">
        {{ 'menu.tabs.happyHours' | translate }}
      </button>
    </ng-template>

    <span class="glider"></span>
  </div>

  <div *ngIf="tabs.activeId == 'tabMain'" appActivitySection="MenuMain">
    <div class="categories" [class.categories__tabs]="isTabsShow" *ngIf="!mainMenusCurrent">

      <img class="banner" src="/themes/canadian-brewhouse/assets/images/menu_game_banner.png" alt="Menu banner">

      <button
        class="category"
        *ngFor="let menu of mainMenus"
        (click)="setMainMenusCurrent(menu)"
        [appUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
      >
        {{ menu.name }}
      </button>

      <div class="empty" *ngIf="mainMenus.length === 0">
        <img src="/themes/canadian-brewhouse/assets/images/menu_enabled.svg" alt="" />
        <div>{{ 'menu.empty' | translate }}</div>
      </div>
    </div>

    <div class="menu" [class.menu__tabs]="isTabsShow" *ngIf="mainMenusCurrent">
      <pinch-zoom *ngFor="let url of mainMenusCurrent.pagesLocal" [properties]="pinchZoomProperties">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </div>

  <div *ngIf="tabs.activeId == 'tabHappyHours'" appActivitySection="MenuHappyHours">
    <div class="categories" [class.categories__tabs]="isTabsShow" *ngIf="!happyHoursMenusCurrent">

      <img class="banner" src="/themes/canadian-brewhouse/assets/images/menu_game_banner.png" alt="Menu banner">

      <button
        class="category"
        *ngFor="let menu of happyHoursMenus"
        (click)="setHappyHoursMenusCurrent(menu)"
        [appUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
      >
        {{ menu.name }}
      </button>

      <div class="empty" *ngIf="happyHoursMenus.length === 0">
        <img src="/themes/canadian-brewhouse/assets/images/menu.svg" alt="" />
        <div>{{ 'menu.empty' | translate }}</div>
      </div>
    </div>

    <div class="menu" [class.menu__tabs]="isTabsShow" *ngIf="happyHoursMenusCurrent">
      <pinch-zoom *ngFor="let url of happyHoursMenusCurrent.pagesLocal" [properties]="pinchZoomProperties">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </div>
</ng-template>

